/* eslint-disable no-undef */

import $ from "jquery"
import "../css/reset.less"
import "../css/index.less"
import WeChatShare from "./WeChatShare";
// import VConsole from "vconsole";
// new VConsole()

import {loaderImg} from "./loading"

$(function () {
    
    new WeChatShare('https://wx-dyxx.csshavas.cn/wx-dyxx/wx/config','表情包合集','点击链接查看详情')
    const urlHost = 'https://havas-1314064649.cos.ap-shanghai.myqcloud.com/web/h5/biaoqingbao/'
    let classifyObj = [];
    const randomNub = Math.random();

    $.ajax({
        url:"https://havas-1314064649.cos.ap-shanghai.myqcloud.com/web/h5/biaoqingbao/biaoqingbao.json",
            type:'GET'
        }).done(function(data) {
           classifyObj = data;
           initHtmlFn();
        })
        .fail(function() {
            alert("请联系管理员")
        });

    function initHtmlFn(){
        let _html = ''
        for(let i = 0;i < classifyObj.length;i ++){
            _html += createTitleHtml(classifyObj[i].titleurl);
            _html += createHtmlFn(classifyObj[i].data)
        }

        let loadingOtherImg = [];
        let maxLength = classifyObj[0].data.length > 6 ? 6 : classifyObj[0].data.length ;
        for(let j = 0;j <maxLength;j ++){
            loadingOtherImg.push(urlHost+'gif/'+classifyObj[0].data[j]+'?'+randomNub);
        }

        loaderImg(".gifcont",loadingOtherImg,function(){},function(){
            $(".pageCont").css("opacity",1)
        });
        $(".gifcont").html(_html)
        
    }


    function createHtmlFn(datas){

        let returnHtml = ' <div class="gifcontainer">'

        for(let j = 0;j < datas.length;j ++ ){
            returnHtml += `
            <div class="gifmodel">
                <img src="${urlHost}kuang.png" class="di"/>
                <div class="gifImg">
                    <img src="${urlHost}gif/${datas[j]}?${randomNub}"/>
                </div>
                <img src="${urlHost}kuang.png" class="kuang"/>
                <img src="${urlHost}gif/${datas[j]}?${randomNub}" class="gifSent"/>
            </div>
        `
        }
        returnHtml += '</div>'
        return returnHtml;


    }
    function createTitleHtml(titleName){
        return `
        <div class="gifTitels">
            <img src="${urlHost}${titleName}"/>
        </div>
        `
    }

   


    console.log(getUrlParam("a"))

    function getUrlParam(name){
        let reg = new RegExp("(^|&)"+ name +"=([^&]*)(&|$)")
        let r = window.location.search.substr(1).match(reg)
        if (r!=null) return unescape(r[2])
        return null
    }



});