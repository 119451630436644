import $ from "jquery"
export function loaderImg(names,otherLoadingImg,speedFn,endFn){
    let containerImgArr = [...otherLoadingImg];
    $(names+" img").each(function(){
        let img_url = $(this).attr("src");
        if(img_url.indexOf("base64") === -1){
            containerImgArr.push(img_url);
        }
    })
    let containerImgArrLength = containerImgArr.length;
    if(containerImgArrLength> 0){
        imgLoader(0)
    }else{
        endFn();
    }
    function imgLoader(_index){
        let img = new Image();
        img.onload = function(){
            _index++;
            if(_index < containerImgArrLength){
                speedFn( parseInt(_index/containerImgArrLength*100))
                imgLoader(_index)
            }else{
                endFn();
            }
        }
        img.src = containerImgArr[_index];
    }
}