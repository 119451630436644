/* eslint-disable no-undef */
import $ from "jquery"

export default class WeChatShare{
    /**
     * 
     * @param {*} apiUrl  分享接口链接
     * @param {*} messageTitle 分享好友title
     * @param {*} messageDec 分享好友描述
     * @param { } shareImgUrl 分享 图片  不带host
     * @param { } shareUrl 分享链接
     * @param { } timelineTitle 分享朋友圈title
     * @param { } timelineDec 分享朋友圈描述
     */
    constructor(apiUrl,messageTitle,messageDec,shareImgUrl='',shareUrl='',timelineTitle='',timelineDec=''){
        let {host,href,protocol} = window.location; 
        this.host = protocol+"//"+ host;
        this.shareUrl =shareUrl===''? href: shareUrl;
        this.apiUrl = apiUrl;
        this.messageTitle = messageTitle;
        this.messageDec = messageDec;
        this.shareImgUrl = shareImgUrl === '' ? '/image/fx.jpg?'+Math.random() : shareImgUrl;
        this.timelineTitle = timelineTitle === ''? messageTitle : timelineTitle;
        this.timelineDec = timelineDec === ''? messageDec : timelineDec;
        this.init();
    }
    init(){
        const _this = this;
        $.ajax({
            type: "get",//方法类型
            dataType: "json",//预期服务器返回的数据类型
            url: _this.apiUrl,
            data: {url: encodeURIComponent(_this.shareUrl)},
            success: function (result) {
                wx.config({
                    debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                    appId: result.data.appId, // 必填，公众号的唯一标识
                    timestamp: result.data.timestamp, // 必填，生成签名的时间戳
                    nonceStr: result.data.nonceStr, // 必填，生成签名的随机串
                    signature: result.data.signature,// 必填，签名
                    jsApiList:['updateAppMessageShareData','updateTimelineShareData','openLocation'],
                    openTagList:['wx-open-launch-weapp']
                });

                wx.ready(function() {
                    wx.updateAppMessageShareData({
                        title: _this.messageTitle, // 分享标题
                        desc: _this.messageDec, // 分享描述
                        link:_this.shareUrl, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                        imgUrl: _this.host+ _this.shareImgUrl, // 分享图标
                        success: function () {
                            // 设置成功
                        }
                    });

                    wx.updateTimelineShareData({
                        title: _this.timelineTitle, // 分享标题
                        desc: _this.timelineDec, // 分享描述
                        link: _this.shareUrl, 
                        imgUrl: _this.host+ _this.shareImgUrl, // 分享图标
                        success: function () {
                            // 设置成功
                        }
                    });
                })

                wx.error(function(res){
                    console.log(res);
                });
            },
            error : function(result) {
                console.log(result);
            }
        });
    }
    updateShareMessage(titles,desc,link='',imgUrl=''){
        const _this = this;
        const ImgUrl = imgUrl === ""? _this.shareImgUrl : imgUrl;
        wx.updateAppMessageShareData({
            title:titles, // 分享标题
            desc: desc, // 分享描述
            link:link===""?_this.shareUrl:link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
            imgUrl: _this.host+ ImgUrl, // 分享图标
            success: function () {
                // 设置成功
            }
        });
    }
    updateShareTimeline(titles,desc,link='',imgUrl=''){
        const _this = this;
        const ImgUrl = imgUrl === ""? _this.shareImgUrl : imgUrl;
        wx.updateTimelineShareData({
            title:titles, // 分享标题
            desc:desc, // 分享描述
            link: link===""?_this.shareUrl:link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
            imgUrl: _this.host+ ImgUrl, // 分享图标
            success: function () {
                // 设置成功
            }
        });
    }


}

